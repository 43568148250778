<template>
    <span>
        <b-button @click="manualSave" variant="outline-secondary" pill
                  size="sm" v-b-tooltip.hover="tooltip" class="ml-2">
            <font-awesome-icon :icon="icon" :pulse="pulse"/>
        </b-button>

        <b-button @click="listBrouillons" variant="outline-secondary" pill
                  size="sm" v-b-tooltip.hover="'Liste des brouillons'" class="ml-2">
            <font-awesome-icon icon="bars"/>
        </b-button>

        <b-checkbox v-model="mustAutoSave" class="ml-2" switch
                    v-b-tooltip.hover="'Enregistrement automatique du brouillon'"/>
    </span>
</template>

<script>
    import {apiPath} from '@/util/http';

    const ListBrouillonArticleModal = () => import('@/components/modals/wiki/ListBrouillonArticleModal');

    export default {
        name: "BrouillonToolbarWiki",
        props: {
            corps: {
                required: true
            },
            articleId: {
                type: Number,
                required: true
            }
        },
        data: () => ({
            status: null,
            mustAutoSave: true,
            saveInterval: null,
            lastSavedContent: null
        }),
        computed: {
            icon() {
                switch (this.status) {
                    case 'saving':
                        return 'spinner';
                    case 'success':
                        return ['far', 'check-circle'];
                    case 'fail':
                        return ['far', 'times-circle'];
                }

                return ['far', 'circle'];
            },
            pulse() {
                return this.status === 'saving';
            },
            tooltip() {
                switch (this.status) {
                    case 'saving':
                        return 'Enregistrement en cours';
                    case 'success':
                        return 'Brouillon enregistré';
                    case 'fail':
                        return "Erreur d'enregistrement du brouillon";
                }

                return 'Brouillon non-enregistré';
            }
        },
        methods: {
            save() {
                if (this.corps && this.corps.trim().length && this.corps !== this.lastSavedContent) {
                    this.status = 'saving';
                    this.axios
                        .post(apiPath('wiki_brouillon_new', {article: this.articleId}), {
                            corps: this.corps
                        })
                        .then(() => {
                            this.lastSavedContent = this.corps;
                            this.status           = 'success';
                        })
                        .catch(() => this.status = 'fail');
                }
            },
            manualSave() {
                if (status !== 'saving') {
                    this.restartAutoSave();
                    this.save();
                }
            },
            startAutoSave() {
                this.stopAutoSave();
                this.saveInterval = setInterval(() => this.save(), 30000);
            },
            restartAutoSave() {
                if (this.mustAutoSave)
                    this.startAutoSave();
            },
            stopAutoSave() {
                if (this.saveInterval) {
                    clearInterval(this.saveInterval);
                    this.saveInterval = null;
                }
            },
            listBrouillons() {
                this.$store.dispatch('modal/create', {
                    component: ListBrouillonArticleModal,
                    props: {
                        "article-id": this.articleId,
                        "on-select": ({corps}) => this.$emit('loadCorps', corps)
                    }
                });
            }
        },
        watch: {
            mustAutoSave: {
                immediate: true,
                handler(val) {
                    this.mustAutoSave = val;

                    this.mustAutoSave ? this.startAutoSave() : this.stopAutoSave();
                }
            }
        },
        beforeDestroy() {
            if (this.saveInterval)
                clearInterval(this.saveInterval);
        }
    }
</script>

<style scoped>
    div {
        display: inline-block;
    }
</style>